import User from "./User";
import { portalUser } from "../../../App";
import { formatDate, convertArrayToMap, log } from "../../../helperFunctions";

export default class PREmployee extends User {
	constructor(employee, user = new User()) {
		super(user);
		if (employee instanceof PREmployee) {
			Object.assign(this, employee);
		} else {
			this.queryData = employee?.queryData ?? "PREmployee";

			// INCLUDE IN PAYROLL EMPLOYEES
			this.employeeUID = employee?.employeeUID ?? employee?.EmployeeUID ?? null;
			this.prehKeyID = employee?.prehKeyID ?? employee?.PREHKeyID ?? null;
			this.prco = employee?.prco ?? employee?.PRCo ?? "1";
			this.prGroup = employee?.prGroup ?? employee?.PRGroup ?? null;
			this.prDept = employee?.prDept ?? employee?.PRDept ?? null;
			this.employee = employee?.employee ?? employee?.Employee ?? null;

			this.legalFirstName = employee?.legalFirstName ?? employee?.LegalFirstName ?? null;
			this.legalLastName = employee?.legalLastName ?? employee?.LegalLastName ?? null;
			this.firstName = employee?.firstName ?? employee?.FirstName ?? null;
			this.lastName = employee?.lastName ?? employee?.LastName ?? null;
			this.midName = employee?.midName ?? employee?.MidName ?? null;
			this.name = employee?.name ?? null;

			this.craft = employee?.craft ?? employee?.Craft ?? null;
			this.class = employee?.class ?? employee?.Class ?? null;
			this.classDesc = employee?.classDesc ?? employee?.ClassDesc ?? null;
			this.employeeRole = employee?.employeeRole ?? employee?.EmployeeRole ?? "O";
			this.employeeType = employee?.employeeType ?? employee?.EmployeeType ?? null;

			this.activeYN = employee?.activeYN ?? employee?.ActiveYN ?? "N";
			this.hireDate = formatDate(employee?.hireDate ?? employee?.HireDate) ?? null;
			this.termDate = formatDate(employee?.termDate ?? employee?.TermDate) ?? null;
			this.recentRehireDate = formatDate(employee?.recentRehireDate ?? employee?.RecentRehireDate) ?? null;

			this.availableSick = employee?.availableSick ?? employee?.AvailableSick ?? 0;
			this.availableVacation = employee?.availableVacation ?? employee?.AvailableVacation ?? 0;
			this.accrualRate = employee?.accrualRate ?? employee?.AccrualRate ?? 0;
			this.maxAvailable = employee?.maxAvailable ?? employee?.MaxAvailable ?? 0;

			this.paystubEmail = employee?.paystubEmail ?? employee?.PaystubEmail ?? null;

			this.title = employee?.title ?? employee?.Title ?? employee?.classDesc ?? employee?.ClassDesc ?? null;
			this.email = employee?.email ?? employee?.Email ?? null;
			this.cellPhone = employee?.cellPhone ?? employee?.CellPhone ?? null;

			this.regionID = employee?.regionID?.toString() ?? employee?.RegionID?.toString() ?? "0";
			this.deptID = (employee?.deptID ?? employee?.DeptID)?.toString() ?? "0";
			this.tradeID = (employee?.tradeID ?? employee?.TradeID)?.toString() ?? "1";

			this.companyName = employee?.companyName ?? "Pan-Pacific Mechanical";
			this.companyAbbr = employee?.companyAbbr ?? "PPM";

			this.region = employee?.region ?? employee?.Region ?? null;
			this.officePhone = employee?.officePhone ?? employee?.OfficePhone ?? null;
			this.officeExt = employee?.officeExt ?? employee?.OfficeExt ?? null;

			this.dept = employee?.dept ?? null;
			this.deptAbbr = employee?.deptAbbr ?? null;
			this.parentDeptID = employee?.parentDeptID ?? null;
			this.parentDept = employee?.parentDept ?? null;
			this.parentDeptAbbr = employee?.parentDeptAbbr ?? null;

			this.trade = employee?.trade ?? employee?.Trade ?? null;

			this.userStatus = employee?.userStatus ?? user?.userStatus ?? "Active";
			this.leaveStatus = employee?.userStatus
				? employee?.userStatus === "Active"
					? "N/A"
					: employee?.userStatus
				: "N/A";
			this.activeYN = employee?.activeYN ?? employee?.ActiveYN ?? "N";
			this.hireDate = formatDate(employee?.hireDate ?? employee?.HireDate) ?? null;
			this.termDate = formatDate(employee?.termDate ?? employee?.TermDate) ?? null;
			this.birthday = formatDate(employee?.birthday ?? employee?.Birthday) ?? null;

			this.longevityMths = employee?.longevityMths ?? employee?.LongevityMths ?? 0;
			this.longevityYears = employee?.longevityYears ?? employee?.LongevityYears ?? 0;
			this.longevity = employee?.longevity ?? null;
			this.lastLongevityAward = employee?.lastLongevityAward ?? null;
			this.lastLongevityAwardYear = employee?.lastLongevityAwardYear ?? null;

			this.shareBirthdayYN = user?.shareBirthdayYN === "Y" ? "Shared" : "Hidden";
			this.sharePhotosYN = user?.sharePhotosYN === "Y" ? "Shared" : "Hidden";

			//for the rest of HR Employees see User class

			this.homeAddress = employee?.homeAddress ?? employee?.HomeAddress ?? null;
			this.homeCity = employee?.homeCity ?? employee?.HomeCity ?? null;
			this.homeState = employee?.homeState ?? employee?.HomeState ?? null;
			this.homeZip = employee?.homeZip ?? employee?.HomeZip ?? null;
			this.homePhone = employee?.homePhone ?? employee?.HomePhone ?? null;

			this.jcFixedRate = employee?.jcFixedRate ?? employee?.JCFixedRate ?? 0;
		}
		this.getName();
		this.getCompany();
		this.getRegion();
		this.getDepartment();
		this.getRole();
		this.getLongevity();
	}

	getCompany(companyID = this.prco) {
		let companyMap = convertArrayToMap(portalUser.getCompanies(), "jcco") ?? null;
		companyID = companyID?.toString();
		let company = companyMap[companyID] ?? null;
		this.companyName = company?.company ?? null;
		this.companyAbbr = company?.tag ?? null;
		return company;
	}

	getRegion(regionID = this.regionID) {
		let regionMap = convertArrayToMap(portalUser.getRegions(), "regionID") ?? null;
		regionID = regionID?.toString();
		let region = regionMap[regionID] ?? null;
		this.region = region?.region ?? null;
		this.officePhone = region?.phone ?? null;
		if (Boolean(this.officeExt)) {
			this.officePhone += " x" + this.officeExt;
		}
		return region;
	}

	getDepartment(deptID = this.deptID) {
		let deptMap = convertArrayToMap(portalUser.getDepartments(), "deptID") ?? null;
		deptID = deptID?.toString();
		let dept = deptMap[deptID] ?? null;
		let parentDept = deptMap[dept?.parentDeptID] ?? dept;
		this.dept = dept?.dept ?? null;
		this.deptAbbr = dept?.tag ?? null;
		this.parentDeptID = parentDept?.deptID ?? null;
		this.parentDept = parentDept?.dept ?? null;
		this.parentDeptAbbr = parentDept?.tag ?? null;
		return dept;
	}

	getRole(roleID = this.roleID) {
		let roleMap = convertArrayToMap(portalUser.getRoles(), "roleID") ?? null;
		roleID = roleID?.toString();
		let role = roleMap[roleID] ?? null;
		this.role = role?.role ?? null;
		this.roleAbbr = role?.tag ?? null;
		return role;
	}

	getLongevity(years = this.longevityYears ?? 0) {
		years = parseFloat(years);
		let actualYears = Math.floor(years);
		let remainingMths = years - parseFloat(parseInt(actualYears));
		remainingMths = parseFloat(remainingMths) * parseFloat(12);
		remainingMths = Math.round(remainingMths);

		let longevity = "";
		if (actualYears > 0) {
			longevity += actualYears + " yr";
			if (actualYears > 1) {
				longevity += "s";
			}
		}
		if (longevity?.length > 0) {
			longevity += ", ";
		}

		if (remainingMths > 0) {
			longevity += remainingMths + " mth";

			if (remainingMths > 1) {
				longevity += "s";
			}
		}
		this.longevity = longevity;
		return longevity;
	}

	async updatePaystubEmail(value) {
		this.paystubEmail = value.replace("'", "`").toLowerCase();
		let updates = {
			"p.Email": this.paystubEmail,
		};
		return await this.updatePREmployeeDB(updates);
	}

	async updateHomeAddress(value) {
		this.homeAddress = value.replace("'", "`");
		let updates = {
			"p.Address": this.homeAddress,
		};
		return await this.updatePREmployeeDB(updates);
	}

	async updateHomeCity(value) {
		this.homeCity = value.replace("'", "`");
		let updates = {
			"p.City": this.homeCity,
		};
		return await this.updatePREmployeeDB(updates);
	}

	async updateHomeState(value) {
		this.homeState = value.replace("'", "`").toUpperCase();
		let updates = {
			"p.State": this.homeState,
		};

		return await this.updatePREmployeeDB(updates);
	}

	async updateHomeZip(value) {
		this.homeZip = value.replace("'", "`");
		let updates = {
			"p.Zip": this.homeZip,
		};
		return await this.updatePREmployeeDB(updates);
	}

	//USER
	//Updates budPPMPortal_Employees
	async updateUserDB(data) {
		if (!Boolean(this.employeeUID)) {
			return;
		}
		try {
			let result = await portalUser.usersAPI.PortalUser_UserData_UpdateUser(this.employeeUID, data);
			if (result?.status === 200 && result?.value?.length > 0) {
				result = result?.value[0];
			}
			log("Database Result (update user)", result);
			return result;
		} catch (error) {
			log("Update DB Error: ", error);
			alert("An error occurred! Please refresh the page and try again.");
			return null;
		}
	}

	async updateShowBirthday(value = "N") {
		let viewValue = "N";
		let sqlValue = "0";
		if (Boolean(value)) {
			viewValue = "Y";
			sqlValue = "1";
		}

		this.shareBirthdayYN = viewValue;
		let updates = { ShowBirthday: sqlValue };
		return await this.updateUserDB(updates);
	}

	async updateSharePhotos(value = "N") {
		let viewValue = "N";
		let sqlValue = "0";
		if (Boolean(value)) {
			viewValue = "Y";
			sqlValue = "1";
		}

		this.sharePhotosYN = viewValue;
		let updates = { SharePhotos: sqlValue };
		return await this.updateUserDB(updates);
	}
}

export class SectionTemplatePREmployees {
	constructor() {
		this.template = "PREmployees";
		this.name = "Active Employees";
		this.heading = "Active Employees";
		this.divider = false;
		this.pagebreak = 0;
		this.wrap = true;
		this.type = "virtualTable";
		this.minRows = 1;
		this.maxRows = 22;
		this.subSections = [new SubSectionTemplatePREmployees()];
	}
}

class SubSectionTemplatePREmployees {
	constructor() {
		let data = portalUser?.getPREmployees();
		this.template = "PREmployees";
		this.type = "virtualTable";
		this.show = data?.length > 0 ? true : false;
		this.autoExpandRows = true;
		this.columns = [
			{
				accessor: "prehKeyID",
				Header: "Key ID",
				show: false,
				disableExport: true,
			},
			{
				accessor: "employeeUID",
				Header: "EmployeeUID",
				show: false,
			},
			{
				accessor: "prco",
				Header: "PRCo",
				width: "50%",
			},
			{
				accessor: "prGroup",
				Header: "PR Group",
				valueType: "integer",
				textAlign: "center",
				width: "65%",
			},

			{
				accessor: "employee",
				Header: "Employee",
				width: "75%",
			},

			{
				accessor: "name",
				Header: "Name",
				width: "150%",
				show: false,
			},

			{
				accessor: "firstName",
				Header: "First Name",
			},

			{
				accessor: "lastName",
				Header: "Last Name",
			},

			{
				accessor: "midName",
				Header: "Middle Init",
				width: "60%",
			},

			{
				accessor: "title",
				Header: "Title",
				width: "200%",
			},

			{
				accessor: "craft",
				Header: "Craft",
				width: "85%",
			},

			{
				accessor: "class",
				Header: "Class",
				width: "85%",
			},

			// {
			// 	accessor: "classDesc",
			// 	Header: "Class Description",
			// 	width: "200%",
			// },

			{
				accessor: "prDept",
				Header: "PR Dept",
				show: false,
				width: "70%",
			},

			{
				accessor: "regionID",
				Header: "Region ID",
				show: false,
				disableExport: true,
				width: "60%",
			},
			{
				accessor: "tradeID",
				Header: "Trade ID",
				show: false,
				disableExport: true,
				width: "60%",
			},
			{ accessor: "longevity", Header: "Longevity" },
			{
				accessor: "deptID",
				Header: "Dept ID",
				show: false,
				disableExport: true,
				width: "60%",
			},

			{
				accessor: "parentDeptID",
				Header: "Parent Dept ID",
				show: false,
				disableExport: true,
			},

			{
				accessor: "employeeType",
				Header: "Office / Union",
				width: "70%",
			},
			{
				accessor: "employeeRole",
				Header: "Category",
				show: false,
				disableExport: true,
			},

			{
				accessor: "cellPhone",
				Header: "Cell Phone",
				show: false,
			},
			{
				accessor: "email",
				Header: "Email",
				width: "200%",
			},
			{
				accessor: "activeYN",
				Header: "Active YN",
				width: "70%",
				textAlign: "center",
			},
		];
		this.data = data;
	}
}
