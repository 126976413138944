import Project from "./Project";
import ProjectMgr from "./ProjectMgr";
import ProjectAcct from "./ProjectAcct";
import Purchaser from "./Purchaser";

import Customer from "./Customer";
import Vendor from "../../Purchasing/classes/Vendor";
import DiversityCert from "../../Purchasing/classes/DiversityCert";

import Phase from "./Phase";
import CostType from "./CostType";
import Scope from "./Scope";
import OperationsAPI from "../../../APIs/OperationsAPI";
// import { portalUser } from "../../../App";
// import { log } from "../../../helperFunctions";

export default class Operations {
	constructor(operations) {
		if (operations instanceof Operations) {
			Object.assign(this, operations);
		} else {
			//OPERATIONS
			this.operationsAPI = operations?.operationsAPI ?? null;
			this.projects = operations?.projects ?? [];
			this.projectMgrs = operations?.projectMgrs ?? [];
			this.purchasers = operations?.purchasers ?? [];
			this.projectAccts = operations?.projectAccts ?? [];
			this.customers = operations?.customers ?? [];
			this.vendors = operations?.vendors ?? [];
			this.diversityCerts = operations?.diversityCerts ?? [];
			this.costcodes = operations?.costcodes ?? [];
			this.costtypes = operations?.costtypes ?? [];
			this.scopes = operations?.scopes ?? [];
		}
	}

	classifyData(ops = this, sqlData = []) {
		for (let i = 0; i < sqlData?.length; i++) {
			let queryData = sqlData[i]?.queryData;
			if (queryData === "Project") {
				ops.projects.push(new Project(sqlData[i]));
			} else if (queryData === "Customer") {
				ops.customers.push(new Customer(sqlData[i]));
			} else if (queryData === "Vendor") {
				ops.vendors.push(new Vendor(sqlData[i]));
			} else if (queryData === "DiversityCert") {
				ops.diversityCerts.push(new DiversityCert(sqlData[i]));
			} else if (queryData === "ProjectMgr") {
				ops.projectMgrs.push(new ProjectMgr(sqlData[i]));
			} else if (queryData === "ProjectAcct") {
				ops.projectAccts.push(new ProjectAcct(sqlData[i]));
			} else if (queryData === "Purchaser") {
				ops.purchasers.push(new Purchaser(sqlData[i]));
			} else if (queryData === "Phase") {
				ops.costcodes.push(new Phase(sqlData[i]));
			} else if (queryData === "CostType") {
				ops.costtypes.push(new CostType(sqlData[i]));
			} else if (queryData === "Scope") {
				ops.scopes.push(new Scope(sqlData[i]));
			}
		}

		return ops;
	}

	async initOperations(params = {}) {
		let ops = this;
		if (Boolean(params?.msUserID) && !Boolean(ops?.operationsAPI)) {
			ops.operationsAPI = new OperationsAPI();
			ops = await ops?.getProjects(params);
		}
		return ops;
	}

	async getProjects(params = {}) {
		let ops = this;
		let projects = ops.projects;

		if (Boolean(params?.contractStatus)) {
			projects = projects?.filter((d) => d.contractStatus === params?.contractStatus) ?? [];
		}

		if (projects?.length === 0) {
			let sqlData = await this.operationsAPI.GetProjects({}, params);
			if (sqlData?.status === 200) {
				sqlData = sqlData?.value ?? [];
				ops = this.classifyData(ops, sqlData);
			}
		}
		return ops;
	}

	async getVendors(params = {}) {
		let ops = this;
		let vendors = ops.vendors;

		if (vendors?.length === 0) {
			let sqlData = await this.operationsAPI.GetVendors({}, params);
			if (sqlData?.status === 200) {
				sqlData = sqlData?.value ?? [];
				ops = this.classifyData(ops, sqlData);
			}
		}
		return ops;
	}

	async getDiversityCerts(params = {}) {
		let ops = this;
		let diversityCerts = ops.diversityCerts;

		if (diversityCerts?.length === 0) {
			let sqlData = await this.operationsAPI.GetDiversityCerts({}, params);
			if (sqlData?.status === 200) {
				sqlData = sqlData?.value ?? [];
				ops = this.classifyData(ops, sqlData);
			}
		}
		return ops;
	}
}
