import User from "./User";
import Leader from "./Leader";
import Announcement from "../../Home/classes/Announcement";
import TimeOffRequest from "../../TimeOffRequests/classes/TimeOffRequest";
import LongevityAward from "./LongevityAward";
import Notification from "./Notification";
import CalendarEvent from "../../../core/Calendars/classes/CalendarEvent";
import SecurityGroup from "./SecurityGroup";
import ARCustomer from "../../Operations/classes/Customer";
import APVendor from "../../Purchasing/classes/Vendor";
import DiversityCert from "../../Purchasing/classes/DiversityCert";
import JCProjectMgr from "../../Operations/classes/ProjectMgr";
import PREmployee from "./PREmployee";

import Contract from "../../Contracts/classes/Contract";
import Job from "../../Contracts/classes/Job";

export default class UserData extends Array {
	constructor(userData = [], sqlData = []) {
		super();

		if (Boolean(sqlData) && sqlData?.length > 0) {
			for (let i = 0; i < sqlData?.length; i++) {
				let queryData = sqlData[i]?.queryData ?? sqlData[i]?.QueryData ?? null;
				if (queryData === "User") {
					let user = new User(sqlData[i]);
					userData.push(user);
				} else if (queryData === "PREmployee") {
					let employeeUID = sqlData[i]?.employeeUID ?? sqlData[i]?.EmployeeUID ?? null;
					let user = userData?.find((d) => d.queryData === "User" && d.employeeUID === employeeUID) ?? null;
					let employee = new PREmployee(sqlData[i], user);
					userData.push(employee);
				} else if (queryData === "Leader") {
					userData.push(new Leader(sqlData[i]));
				} else if (queryData === "JCProjectMgr") {
					userData.push(new JCProjectMgr(sqlData[i]));
				} else if (queryData === "ARCustomer") {
					userData.push(new ARCustomer(sqlData[i]));
				} else if (queryData === "APVendor") {
					userData.push(new APVendor(sqlData[i]));
				} else if (queryData === "DiversityCert") {
					userData.push(new DiversityCert(sqlData[i]));
				} else if (queryData === "Announcement") {
					userData.push(new Announcement(sqlData[i]));
				} else if (queryData === "TimeOffRequest") {
					userData.push(new TimeOffRequest(sqlData[i]));
				} else if (queryData === "Notification") {
					userData.push(new Notification(sqlData[i]));
				} else if (queryData === "CalendarEvent") {
					userData.push(new CalendarEvent(sqlData[i]));
				} else if (queryData === "SecurityGroup") {
					userData.push(new SecurityGroup(sqlData[i]));
				} else if (queryData === "LongevityAward") {
					userData.push(new LongevityAward(sqlData[i]));
				} else if (queryData === "Contract") {
					userData.push(new Contract(sqlData[i]));
				} else if (queryData === "Job") {
					userData.push(new Job(sqlData[i]));
				}
			}
		}
		return userData;
	}
}
