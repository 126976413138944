import React, { useState, useMemo, useCallback } from "react";
import { Flex } from "@chakra-ui/react";
import Tabs from "../../core/Reports/components/Tabs";
import Dashboard from "../MFR/pages/MFRProjectSelectPage";
import Page from "./common/Page";
import PrintPreview from "./common/PrintPreview";
import MFRPage from "../MFR/pages/MFRPage";
import MFRProjectionsPage from "../MFR/pages/MFRProjectionsPage";
import ChangeOrderLogPage from "./pages/ChangeOrderLogPage";
import MFRJobCostDrilldownPage from "../MFR/pages/MFRJobCostDrilldownPage";
import { portalUser } from "../../App";
import MainContent from "../../legacy/App/components/MainContent";
import moment from "moment";

// import { log } from "../../helperFunctions";

export default function MFR(props) {
	// Default State Variables
	const [viewerMaxHeight, setViewerMaxHeight] = useState(800);
	const [tabVal, setTabVal] = useState(0);
	const [reportTabs, setReportTabs] = useState([]);
	const [detailTabs, setDetailTabs] = useState([]);
	const [template, setTemplate] = useState("MonthlyForecast");

	const [selectedKeys, setSelectedKeys] = useState([]);
	const [thruMth, setThruMth] = useState(moment(new Date()).startOf("month"));

	const [contract, setContract] = useState(null);
	const [contractData, setContractData] = useState([]);
	// const [updateTrigger, setUpdateTrigger] = useState(false);
	// const [document, setDocument] = useState(portalUser.getDocument("MFR"));

	useMemo(() => {
		let template = "MonthlyForecast";
		if (Boolean(props.template)) {
			template = props.template;
			if (["2", "4", "5"].includes(contract?.contractTypeID)) {
				template = "MonthlyForecastCRS";
			}

			setTemplate(template);
		}
	}, [contract?.contractTypeID, props.template]);

	// Handle Tab Change on Click
	const changeTab = useCallback((e) => {
		setTabVal(e);
		window.scrollTo({
			top: 0,
			behavior: "smooth",
		});
	}, []);

	const closeForm = useCallback(() => {
		setTabVal(0);
		window.scrollTo({
			top: 0,
			behavior: "smooth",
		});
	}, []);

	const printForm = useCallback(
		(e) => {
			setTabVal(reportTabs?.length + 2);

			window.scrollTo({
				top: 0,
				behavior: "smooth",
			});
		},
		[reportTabs?.length]
	);

	const getContractDetail = useCallback(async (jccmKeyID = []) => {
		let contractData = [];

		contractData = await portalUser.getContractDetail(jccmKeyID);

		setContractData(contractData);
	}, []);

	// Asychronous Call to get the Selected Data based off the Selected Keys
	const getContractData = useCallback(
		async (jccmKeyID = [], clearCache = false) => {
			let contractData = (await portalUser?.getContractData(jccmKeyID, clearCache)) ?? [];
			let contract = contractData?.find((d) => jccmKeyID?.includes(d.jccmKeyID)) ?? null;
			let thruMth = contract?.thruMth ?? moment(new Date()).startOf("month").format("MM/DD/YYYY");

			if (Boolean(contract)) {
				contract.updateContract();
			}

			setContract(contract);
			setThruMth(thruMth);
			setContractData(contractData);
			getContractDetail(jccmKeyID);
		},
		[getContractDetail]
	);

	// Submit the Selected Keys and Change to the First Report Tab
	const submitKeys = useCallback(
		(jccmKeyID = [], resetTemplate = false, clearCache = false) => {
			setSelectedKeys(jccmKeyID);

			let contractData = [];
			let contract = null;
			let thruMth = moment(new Date()).startOf("month").format("MM/DD/YYYY");
			if (!Boolean(resetTemplate)) {
				let contracts = portalUser?.getContracts();
				contract = contracts?.find((d) => jccmKeyID?.includes(d.jccmKeyID)) ?? null;
				if (Boolean(contract?.thruMth)) {
					thruMth = contract?.thruMth;
				}
			}
			setContractData(contractData);
			setContract(contract);
			setThruMth(thruMth);

			if (!Boolean(resetTemplate)) {
				getContractData(jccmKeyID, clearCache);
			}

			if (tabVal === 0) {
				changeTab(1);
			}
		},
		[getContractData, tabVal, changeTab]
	);

	const selectContract = useCallback(
		(jccmKeyID = null, resetTemplate = false, clearCache = false) => {
			let jccmKeys = [];
			if (!resetTemplate) {
				jccmKeys.push(jccmKeyID);
			}

			submitKeys(jccmKeys, resetTemplate, clearCache);
		},
		[submitKeys]
	);

	const selectThruMth = useCallback((thruMth) => {
		setThruMth(thruMth);
	}, []);

	// Get the Report Template and Report Tabs for a Selected Report Type & Update the Tab Page if the Selected Data Changes
	useMemo(() => {
		let reportTabs = [];

		if (["MonthlyForecast", "MonthlyForecastCRS"].includes(template)) {
			reportTabs.push({
				name: "Monthly Forecast Report",
				pages: [
					<Page
						closeForm={closeForm}
						printForm={printForm}
						template={template}
						selectedKeys={selectedKeys}
						thruMth={thruMth}
						selectContract={selectContract}
						selectThruMth={selectThruMth}
						maxW="container.xl"
						title={
							"Monthly Forecast Report" +
							(["2", "4", "5"].includes(contract?.contractTypeID) ? " CRS" : "")
						}
						contract={contract}
						contractData={contractData}
					>
						<MFRPage
							pagebreak={0}
							contract={contract}
							thruMth={thruMth}
							contractData={contractData}
							changeTab={() => changeTab(4)}
							selectedKeys={selectedKeys}
						/>
					</Page>,
					<Page
						maxW={["2", "4", "5"].includes(contract?.contractTypeID) ? "full" : "container.xl"}
						title={
							"Monthly Forecast Report" +
							(["2", "4", "5"].includes(contract?.contractTypeID) ? " CRS" : "")
						}
						printForm={printForm}
						contract={contract}
						thruMth={thruMth}
						selectContract={selectContract}
						selectThruMth={selectThruMth}
						contractData={contractData}
						closeForm={closeForm}
					>
						<MFRPage
							pagebreak={1}
							contract={contract}
							thruMth={thruMth}
							contractData={contractData}
							selectContract={selectContract}
						/>
					</Page>,
					<Page
						maxW="container.xl"
						title={
							"Monthly Forecast Report" +
							(["2", "4", "5"].includes(contract?.contractTypeID) ? " CRS" : "")
						}
						printForm={printForm}
						contract={contract}
						thruMth={thruMth}
						selectContract={selectContract}
						selectThruMth={selectThruMth}
						contractData={contractData}
						closeForm={closeForm}
					>
						<MFRPage pagebreak={2} contract={contract} thruMth={thruMth} contractData={contractData} />
					</Page>,
				],
			});
		}
		if (["MonthlyForecast", "MonthlyForecastCRS", "ManpowerProjections"].includes(template)) {
			reportTabs.push({
				name: "Manpower Projections",
				pages: [
					<Page
						minH="7xl"
						maxW="full"
						title={"Manpower Projections"}
						printForm={printForm}
						contract={contract}
						thruMth={thruMth}
						selectContract={selectContract}
						selectThruMth={selectThruMth}
						contractData={contractData}
						closeForm={closeForm}
					>
						<MFRProjectionsPage
							selectedKeys={selectedKeys}
							pagebreak={0}
							thruMth={thruMth}
							contractData={contractData}
						/>
					</Page>,
				],
			});
		}
		if (["MonthlyForecast", "MonthlyForecastCRS", "ManpowerProjections"].includes(template)) {
			reportTabs.push({
				name: "Billing & Cost Projections",
				pages: [
					<Page
						minH="7xl"
						maxW="full"
						title={"Billing & Cost Projections"}
						printForm={printForm}
						contract={contract}
						thruMth={thruMth}
						selectContract={selectContract}
						selectThruMth={selectThruMth}
						contractData={contractData}
						closeForm={closeForm}
					>
						<MFRProjectionsPage
							selectedKeys={selectedKeys}
							pagebreak={1}
							thruMth={thruMth}
							contractData={contractData}
						/>
					</Page>,
				],
			});
		}
		if (["MonthlyForecast", "MonthlyForecastCRS", "ChangeOrderLogPage"].includes(template)) {
			reportTabs.push({
				name: "Change Order Log",
				pages: [
					<Page
						minH="7xl"
						maxW="full"
						title={"Change Order Log"}
						printForm={printForm}
						contract={contract}
						selectContract={selectContract}
						selectThruMth={selectThruMth}
						closeForm={closeForm}
					>
						<ChangeOrderLogPage pagebreak={0} selectedKeys={selectedKeys} contractData={contractData} />
					</Page>,
				],
			});
		}

		setReportTabs(reportTabs);
	}, [
		changeTab,
		closeForm,
		printForm,

		template,
		selectedKeys,
		thruMth,

		selectContract,
		selectThruMth,

		contract,
		contractData,
	]);

	useMemo(() => {
		let detailTabs = [];

		if (["MonthlyForecast", "MonthlyForecastCRS"].includes(template)) {
			detailTabs.push({
				name: "Job Cost Drilldown",
				pages: [
					<Page
						minH="7xl"
						maxW="full"
						title={"Job Cost Drilldown"}
						printForm={printForm}
						contract={contract}
						thruMth={thruMth}
						selectContract={selectContract}
						selectThruMth={selectThruMth}
						contractData={contractData}
						closeForm={closeForm}
						viewerMaxHeight={viewerMaxHeight}
					>
						<MFRJobCostDrilldownPage
							pagebreak={0}
							thruMth={thruMth}
							contractData={contractData}
							selectedKeys={selectedKeys}
						/>
					</Page>,
				],
			});
		}
		setDetailTabs(detailTabs);
	}, [
		template,
		contract,
		thruMth,
		selectContract,
		selectThruMth,
		contractData,
		closeForm,
		printForm,
		viewerMaxHeight,
		selectedKeys,
	]);

	// Create Tabs
	const tabs = useMemo(() => {
		const dashboard = [
			{
				name: "Select Project",
				pages: [
					<Dashboard
						selectedKeys={selectedKeys}
						setSelectedKeys={setSelectedKeys}
						selectContract={selectContract}
						viewerMaxHeight={viewerMaxHeight}
					/>,
				],
			},
		];

		const printPreview = [
			{
				name: "Print Preview",
				printPreview: true,
				pages: [<PrintPreview selectedKeys={selectedKeys} thruMth={thruMth} template={template} />],
			},
		];

		return [...dashboard, ...reportTabs, ...detailTabs, ...printPreview];
	}, [selectedKeys, selectContract, viewerMaxHeight, thruMth, template, reportTabs, detailTabs]);

	return (
		<MainContent tabVal={tabVal} onTabChange={changeTab} title={tabs[tabVal]?.name}>
			<Flex bg="blackAlpha.500" direction="column" flex={1} justify="flex-start" w="full" h="full" py={4} px={6}>
				<Tabs
					tabs={tabs}
					tabVal={tabVal}
					changeTab={changeTab}
					viewerMaxHeight={viewerMaxHeight}
					setViewerMaxHeight={setViewerMaxHeight}
				/>
			</Flex>
		</MainContent>
	);
}

// const multiSelectContract = useCallback(
// 	(keyID = null, multiSelect = false, clearSelected = false, aggregate = false, submit = false) => {
// 		if (multiSelect) {
// 			let keys = [...new Set(selectedKeys?.filter((key) => key !== undefined && key !== null))];
// 			if (keys.includes(keyID)) {
// 				keys = keys?.filter((key) => key !== keyID);
// 			} else {
// 				keys.push(keyID);
// 			}
// 			if (clearSelected) {
// 				keys = [];
// 			}
// 			if (submit) {
// 				submitKeys(keys, aggregate);
// 			} else {
// 				setSelectedKeys(keys);
// 			}
// 		} else {
// 			let keys = [keyID];
// 			if (clearSelected) {
// 				keys = [];
// 			}
// 			submitKeys(keys);
// 		}
// 	},
// 	[selectedKeys, submitKeys]
// );
