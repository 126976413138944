import OperationsAPI from "../../../APIs/OperationsAPI";
import Email from "../../Employee/classes/Email";
import moment from "moment";
import { log } from "../../../helperFunctions";

export default class Vendor {
	constructor(vendor) {
		this.operationsAPI = new OperationsAPI();
		this.queryData = vendor?.queryData ?? "APVendor";
		this.apvmKeyID = vendor?.apvmKeyID ?? null;
		this.vendorGroup = vendor?.vendorGroup ?? null;
		this.vendorID = vendor?.vendorID ?? null;
		this.vendorName = vendor?.vendorName ?? null;
		this.vendorSortName = vendor?.vendorSortName ?? null;
		this.vendorPhone = vendor?.vendorPhone ?? null;
		this.vendorShowHideYN = vendor?.vendorShowHideYN ?? "Y";
		this.poAddress = vendor?.poAddress ?? null;
		this.poAddress2 = vendor?.poAddress2 ?? null;
		this.poCity = vendor?.poCity ?? null;
		this.poState = vendor?.poState ?? null;
		this.poZip = vendor?.poZip ?? null;

		this.diversityURL = vendor?.diversityURL ?? null;
		this.diversityContact = vendor?.diversityContact ?? null;
		this.diversityEmail = vendor?.diversityEmail ?? null;
		this.diversityPhone = vendor?.diversityPhone ?? null;
		this.diversityServiceType = vendor?.diversityServiceType ?? null;

		this.sbe = vendor?.sbe ?? null;
		this.sbeStatus = vendor?.sbeStatus ?? null;
		this.lbe = vendor?.lbe ?? null;
		this.lbeStatus = vendor?.lbeStatus ?? null;
		this.mbe = vendor?.mbe ?? null;
		this.mbeStatus = vendor?.mbeStatus ?? null;
		this.wbe = vendor?.wbe ?? null;
		this.wbeStatus = vendor?.wbeStatus ?? null;
		this.dbe = vendor?.dbe ?? null;
		this.dbeStatus = vendor?.dbeStatus ?? null;
		this.dvbe = vendor?.dvbe ?? null;
		this.dvbeStatus = vendor?.dvbeStatus ?? null;
		this.cbe = vendor?.cbe ?? null;
		this.cbeStatus = vendor?.cbeStatus ?? null;
		this.lsbe = vendor?.lsbe ?? null;
		this.lsbeStatus = vendor?.lsbeStatus ?? null;

		this.diversityCerts = vendor?.diversityCerts ?? [];

		this.subtitle = vendor?.subtitle ?? this.getSubtitle();
		this.isDiversityVendorYN = this.getIsDiversityVendorYN();
	}

	getSubtitle() {
		return "(Co: " + this.vendorGroup + "  AP Vendor: " + this.vendorID + ")";
	}

	getIsDiversityVendorYN() {
		let isDiversityVendorYN = "N";
		if (
			Boolean(this.sbe || this.lbe || this.mbe || this.dbe || this.dvbe || this.cbe || this.lsbe || this.wbe) ||
			this.diversityCerts.length > 0
		) {
			isDiversityVendorYN = "Y";
		}
		return isDiversityVendorYN;
	}

	// SQL Insert Diversity Cert
	async insertDiversityCert() {
		this.cert = this.cert?.toString()?.toUpperCase();
		//!Boolean(this.diversityCerts.some((cert) => cert.keyID === keyID)) &&
		if (Boolean(this.apvmKeyID)) {
			let data = {
				vendorGroup: this.vendorGroup,
				vendorID: this.vendorID,
				diversityCert: null,
				expiration: null,
			};
			await this.operationsAPI.InsertDiversityCert({}, data);
			return this;
		}
	}

	async deleteDiversityCert(keyID) {
		let diversityCerts = this.diversityCerts;
		let deleteItem = diversityCerts.find((data) => data?.keyID === keyID) ?? {};
		if (Boolean(deleteItem?.keyID)) {
			deleteItem.deleteDiversityCert();
		}
		this.diversityCerts = diversityCerts.filter((data) => data?.keyID !== keyID);
		return this;
	}

	async sendExpirationEmail() {
		const today = moment().format("MM/DD/YYYY");

		let expiredVendorsResult = await this.operationsAPI.GetExpiredVendors({ daysToExpire: 0 });
		let almostExpiredVendorsResult = await this.operationsAPI.GetExpiredVendors({ daysToExpire: 30 });
		let diversityCertsResult = await this.operationsAPI.GetDiversityCerts();

		let expiredVendors = expiredVendorsResult?.value;
		let almostExpiredVendors = almostExpiredVendorsResult?.value;
		let diversityCerts = diversityCertsResult.value;

		//30 Days prior to expiration
		for (let i = 0; i < 2; i++) {
			let email = new Email();
			email.from = "PPM Purchasing<omare@ppmechanical.com>";
			email.addTo("omare@ppmechanical.com");
			// email.addTo(expiredVendors[i].email);
			// email.replyTo = "omare@ppmechanical.com"
			email.subject = `${expiredVendors[i].cert} Certification Expired`;
			email.body = (
				<p>
					Hi{expiredVendors[i].name ? ` ${expiredVendors[i].name},` : ","}
					<br />
					<br />
					We are reaching out to inform you that your {expiredVendors[i].cert} certification is set to expire{" "}
					in 30 days ({moment(expiredVendors[i].expirationDate).format("MM/DD/YYYY")}). We value our{" "}
					partnership with your business and kindly request your updated certification.
					<br />
					If you have any questions or need assistance, feel free to reach out to us.
					<br />
					Thank you for your prompt attention to this matter.
					<br />
					<br />
					Kind Regards,
					<br />
					PPM Purchasing Team
				</p>
			);
			// email.send();
			// const currentCert = diversityCerts.filter(cert => cert.vendorID === expiredVendors[i].Vendor)
			// await this.operationsAPI.UpdateDiversityCert({keyID: currentCert.vendorID}, {ExpirationReminder1: today})
		}

		//The day after expiration
		for (let i = 0; i < 2; i++) {
			let email = new Email();
			email.from = "PPM Purchasing<omare@ppmechanical.com>";
			email.addTo("omare@ppmechanical.com");
			// email.addTo(expiredVendors[i].email);
			// email.replyTo = "omare@ppmechanical.com"
			email.subject = `${expiredVendors[i].cert} Certification Expired`;
			email.body = (
				<p>
					Hi{expiredVendors[i].name ? ` ${expiredVendors[i].name},` : ","}
					<br />
					<br />
					We are reaching out again to inform you that your {expiredVendors[i].cert} certification has expired{" "}
					as of {moment(expiredVendors[i].expirationDate).format("MM/DD/YYYY")}. We value our partnership with{" "}
					your business and kindly request your updated certification.
					<br />
					<br />
					Kind Regards,
					<br />
					PPM Purchasing Team
				</p>
			);
			// email.send();
			// const currentCert = diversityCerts.filter(cert => cert.vendorID === expiredVendors[i].Vendor)
			// await this.operationsAPI.UpdateDiversityCert({keyID: currentCert.vendorID}, {ExpirationReminder2: today})
		}
	}

	// SQL Insert Diversity Cert
	// async insertVendor2(data) {
	// 	this.cert = this.cert?.toString()?.toUpperCase();
	// 	//!Boolean(this.diversityCerts.some((cert) => cert.keyID === keyID)) &&
	// 	if (Boolean(this.apvmKeyID)) {
	// 		let allData = {
	// 			VendorGroup: 1,
	// 			// Vendor: this.vendorID,
	// 			SortName: null,
	// 			Name: null,
	// 			Type: null,
	// 			TempYN: null,
	// 			Contact: null,
	// 			Phone: null,
	// 			Fax: null,
	// 			EMail: null,
	// 			URL: null,
	// 			Address: null,
	// 			City: null,
	// 			State: null,
	// 			Zip: null,
	// 			Address2: "",
	// 			POAddress: null,
	// 			POCity: null,
	// 			POState: null,
	// 			POZip: null,
	// 			POAddress2: null,
	// 			Purge: "N",
	// 			CustGroup: 1,
	// 			Customer: null,
	// 			TaxGroup: 1,
	// 			TaxCode: null,
	// 			PayTerms: 25,
	// 			GLCo: 1,
	// 			GLAcct: null,
	// 			TaxId: "",
	// 			ActiveYN: "Y",
	// 			EFT: "N",
	// 			RoutingId: null,
	// 			BankAcct: null,
	// 			AcctType: null,
	// 			LastInvDate: null,
	// 			AuditYN: "Y",
	// 			Notes: null,
	// 			UniqueAttchID: null,
	// 			CMAcct: 1015,
	// 			CSEmail: null,
	// 			TaxIdType: null,
	// 			udServiceType: null,
	// 		};
	// 		await this.operationsAPI.InsertVendor(allData);
	// 		return this;
	// 	}
	// }

	// async insertVendor() {
	// 	if (!Boolean(this.id)) {
	// 		//TODO: Validations (unique job)
	// 		let data = (({
	// 			vendorGroup,
	// 			vendorName,
	// 			vendorSortName,
	// 			poAddress,
	// 			poAddress2,
	// 			poCity,
	// 			poState,
	// 			poZip,
	// 			diversityURL,
	// 			diversityContact,
	// 			diversityEmail,
	// 			diversityPhone,
	// 			diversityServiceType,
	// 		}) => ({
	// 			vendorGroup,
	// 			vendorName,
	// 			vendorSortName,
	// 			poAddress,
	// 			poAddress2,
	// 			poCity,
	// 			poState,
	// 			poZip,
	// 			diversityURL,
	// 			diversityContact,
	// 			diversityEmail,
	// 			diversityPhone,
	// 			diversityServiceType,
	// 		}))(this);

	// 		let result = await this.operationsAPI.insertVendor(data);
	// 		if (result?.status === 200 && result?.value?.length > 0) {
	// 			let newVendor = new Vendor(result?.value[0]);
	// 			Object.assign(this, newVendor);
	// 			result.value = this;
	// 		}
	// 		log("Database Result (insert vendor)", result);
	// 		return result;
	// 	}
	// }

	getVendorAttr(attr) {
		// STRINGS
		if (attr === "diversityContact") {
			return "udDiversityContact";
		} else if (attr === "diversityPhone") {
			return "udDiversityPhone";
		} else if (attr === "diversityEmail") {
			return "udDiversityEmail";
		} else if (attr === "diversityURL") {
			return "udCertLink";
		} else if (attr === "diversityServiceType") {
			return "udServiceType";
		} else if (attr === "vendorShowHideYN") {
			return "udShowHideYN";
		}
	}

	async updateVendor(updates) {
		let result = await this.operationsAPI.UpdateVendor({ apvmKeyID: this.apvmKeyID }, updates);
		log("DATABASE RESULT", result?.value);
	}

	// Update the database
	async update(attr = null, value = null) {
		let strings = [
			"diversityContact",
			"diversityEmail",
			"diversityURL",
			"diversityServiceType",
			"diversityPhone",
			"vendorShowHideYN",
		];
		let vendorAttributes = [
			"diversityContact",
			"diversityEmail",
			"diversityURL",
			"diversityServiceType",
			"diversityPhone",
			"vendorShowHideYN",
		];
		let val = null;

		if (strings.includes(attr)) {
			val = value?.replace("'", "`") ?? null;
			if (attr !== "notes") {
				val = val?.replace(/\n/g, " ")?.trim() ?? null;
			}
			this[attr] = val;
		}

		if (Boolean(this.apvmKeyID) && vendorAttributes.includes(attr)) {
			let dbAttribute = this.getVendorAttr(attr);
			let update = { [dbAttribute]: val };
			log("update", update);
			let result = await this.updateVendor(update);
			return result;
		}
	}

	async deleteVendor() {
		let result = await this.operationsAPI.DeleteVendor({ apvmKeyID: this.apvmKeyID });
		log("DATABASE RESULT", result?.value);
	}
}
