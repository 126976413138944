import moment from "moment";
import PageTemplateCRSHome from "./PageTemplateCRSHome";
import PageTemplateCRSProjectAnalysis from "./PageTemplateCRSProjectAnalysis";
import PageTemplateManpowerProjections from "./PageTemplateManpowerProjections";
import PageTemplateCostAndBillingProjections from "./PageTemplateCostAndBillingProjections";
import PageTemplateChangeOrderLog from "../../Contracts/templates/PageTemplateChangeOrderLog";
import PageTemplateCRSCloseout from "./PageTemplateCRSCloseout";

// Monthly Forecast CRS Report Templates
export default class ReportTemplateMonthlyForecastCRS {
	constructor(selectedData = [], thruMth = moment(new Date()).startOf("month")) {
		this.reportTemplate = "MonthlyForecastCRS";
		this.reportName = "Monthly Forecast Report CRS";
		this.pages = [
			new PageTemplateCRSHome(selectedData, thruMth),
			new PageTemplateCRSProjectAnalysis(selectedData, thruMth),
			new PageTemplateManpowerProjections(selectedData, thruMth),
			new PageTemplateCostAndBillingProjections(selectedData, thruMth),
			new PageTemplateChangeOrderLog(selectedData),
			new PageTemplateCRSCloseout(selectedData, thruMth),
		];
	}
}
