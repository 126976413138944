import IFrame from "../../core/Layout/modals/IFrame";

export default function Newsletter() {
	return (
		<IFrame
			variant="simple"
			minH="100vh"
			pageName="Company Newsletter"
			url="https://player.flipsnack.com?hash=N0M5NjdDRjZBRUQrY2kyejhzaWhwOA=="

			// 1. The Pipeline - Q2 2023
			//  a. "https://player.flipsnack.com?hash=N0M5NjdDRjZBRUQrdThxMTRnMDk1ZQ=="
			// 2. KP Catalog - Q1 2023
			// 	"https://player.flipsnack.com?hash=N0M5NjdDRjZBRUQremppZXVqOXpobQ=="
			// 3. The Pipeline - Q2 2022
			// 	a. "https://cdn.flipsnack.com/widget/v2/widget.html?hash=xp0d8aspma"
			// 4. The Pipeline - Q1 2022
			// 	a. "https://cdn.flipsnack.com/widget/v2/widget.html?hash=xnigijpvxn"
			// 5. The Pipeline - Q4 2021
			// 	a. "https://cdn.flipsnack.com/widget/v2/widget.html?hash=dp8b95j7t9"
			// 6. The Pipeline - Q2 2021
			// 	a. "https://cdn.flipsnack.com/widget/v2/widget.html?hash=v7u3rxglqb"
			// 7. The Pipeline - Q1 2021
			// 	a. "https://cdn.flipsnack.com/widget/v2/widget.html?hash=tcjackko7k"
			// 8. The Pipeline Q2 2020
			// 	a. "https://cdn.flipsnack.com/widget/v2/widget.html?hash=zpig5ijd1j"
		/>
	);
}
