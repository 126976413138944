import React, { useState, useEffect } from "react";
import "@fortawesome/fontawesome-free/css/all.css";
import "@fortawesome/fontawesome-free/js/all";
import {
	Flex,
	Center,
	Spacer,
	Avatar,
	AvatarBadge,
	Icon,
	Menu,
	MenuButton,
	MenuList,
	MenuItem,
	Portal,
	Heading,
	Text,
	HStack,
	Stack,
	Link,
	Divider,
} from "@chakra-ui/react";
import { FaUserSecret, FaBell, FaQuestionCircle, FaBug, FaRegLightbulb, FaSignOutAlt } from "react-icons/fa";
import NotificationDrawer from "../../../core/Notifications/components/NotificationsDrawer";
import ImpersonateModal from "./ImpersonateModal";

import { portalUser } from "../../../App";

export default function TopNav({ sideBarButton, startJoyride }) {
	const [notificationDrawerIsOpen, setNotificationDrawer] = useState(false);
	const [impersonateModalIsOpen, setImpersonateModal] = useState(false);

	const [bannerMessage, setBannerMessage] = useState("Welcome to the Team!");
	const [notificationCount, setNotificationCount] = useState(0);

	useEffect(() => {
		let bannerMessage = portalUser?.user?.getBannerMessage() ?? "Welcome to the Team!";
		let notifications = portalUser?.getNotifications(false) ?? [];

		setBannerMessage(bannerMessage);
		setNotificationCount(notifications?.length);
	}, [notificationDrawerIsOpen]);

	return (
		<Flex
			w="full"
			maxW="full"
			position="absolute"
			align="center"
			justify="space-between"
			overflow="hidden"
			bg={"none"}
			shadow={"none"}
			borderBottomWidth={0}
			p="2"
		>
			{portalUser?.user?.isPortalAdminYN === "Y" && (
				<ImpersonateModal isOpen={impersonateModalIsOpen} onClose={() => setImpersonateModal(false)} />
			)}
			{Boolean(portalUser?.user?.employeeUID) && (
				<NotificationDrawer isOpen={notificationDrawerIsOpen} onClose={() => setNotificationDrawer(false)} />
			)}

			<Spacer />
			<Flex justify="content-end" align="center" spacing="4">
				<Spacer />
				<Menu isLazy>
					<Center display={{ base: "none", md: "flex" }}>
						<Stack mr="3" spacing="1">
							<Heading
								textAlign="right"
								as="h3"
								variant="title"
								color="gray.600"
								fontSize={{ base: "14px", md: "16px", lg: "18px" }}
							>
								{portalUser?.user?.getName()}
							</Heading>
							<Text
								textAlign="right"
								fontWeight="semibold"
								color="gray.500"
								fontSize="sm"
								textTransform="uppercase"
							>
								{bannerMessage}
							</Text>
							<HStack spacing={1}>
								<Text
									textAlign="right"
									fontWeight="semibold"
									color="gray.600"
									fontSize="sm"
									textTransform="uppercase"
								>
									Have a bug or idea?
								</Text>
								<MenuButton>
									<Text
										textAlign="right"
										fontWeight="semibold"
										color="blue.600"
										fontSize="sm"
										textDecoration="underline"
										textTransform="uppercase"
										spacing={1}
									>
										Click Here!
									</Text>
								</MenuButton>
							</HStack>
						</Stack>
					</Center>

					<MenuButton>
						<Avatar
							id="avatarMenu"
							key={portalUser?.user?.profilePhoto}
							src={portalUser?.user?.profilePhoto ?? ""}
							icon={
								<Text as="span" color="gray.100" fontSize="2xl">
									<i
										className={
											"fas fa-user" +
											(!Boolean(portalUser?.user?.msUserID === portalUser?.msUser?.id) &&
												"-secret") +
											" fa-lg"
										}
									/>
								</Text>
							}
							size="lg"
							mr="2"
						>
							{notificationCount > 0 && (
								<AvatarBadge bg="teal" boxSize="25px" color="white" border={0}>
									<Text fontSize="sm" fontWeight="semibold">
										{notificationCount}
									</Text>
								</AvatarBadge>
							)}
						</Avatar>
					</MenuButton>
					<Portal>
						<MenuList py={2} px={1} zIndex="120">
							<Stack w="full" spacing={1}>
								<MenuItem color="teal.600" onClick={() => setNotificationDrawer(true)} rounded={5}>
									<HStack spacing="4" align="center">
										<Icon as={FaBell} w="30px" h="30px" />
										<Text
											as="h4"
											color="gray.500"
											fontWeight="bold"
											fontSize="md"
											textTransform="uppercase"
											letterSpacing={2}
										>
											Notifications
										</Text>
									</HStack>
								</MenuItem>

								<MenuItem color="teal.600" onClick={startJoyride} rounded={5}>
									<HStack spacing="4" align="center">
										<Icon as={FaQuestionCircle} w="30px" h="30px" />
										<Text
											as="h4"
											color="gray.500"
											fontWeight="bold"
											fontSize="md"
											textTransform="uppercase"
											letterSpacing={2}
										>
											Portal Tour
										</Text>
									</HStack>
								</MenuItem>

								<MenuItem color="teal.600" rounded={5}>
									<Link
										isExternal
										href="https://forms.office.com/pages/responsepage.aspx?id=GGZ0zUbCzkuv8Z4erUbs5_RXwWay4rxBgUknt9YscmhUNTE1Rk9aNUZSQUJGVTEzU0ZNVzdQR0ZQWiQlQCN0PWcu"
									>
										<HStack spacing="4" align="center">
											<Icon as={FaBug} w="30px" h="30px" />
											<Text
												as="h4"
												color="gray.500"
												fontWeight="bold"
												fontSize="md"
												textTransform="uppercase"
												letterSpacing={2}
											>
												Report a Bug
											</Text>
										</HStack>
									</Link>
								</MenuItem>

								<MenuItem color="teal.600" rounded={5}>
									<Link
										isExternal
										href="https://forms.office.com/pages/responsepage.aspx?id=GGZ0zUbCzkuv8Z4erUbs5_RXwWay4rxBgUknt9YscmhUNjI1Qk9WMVlLUEIyWkRUMjFWRlNFV1NVQiQlQCN0PWcu"
									>
										<HStack spacing="4" align="center">
											<Icon as={FaRegLightbulb} w="30px" h="30px" />
											<Text
												as="h4"
												color="gray.500"
												fontWeight="bold"
												fontSize="md"
												textTransform="uppercase"
												letterSpacing={2}
											>
												Submit an Idea
											</Text>
										</HStack>
									</Link>
								</MenuItem>

								<MenuItem
									color="teal.600"
									onClick={() => {
										portalUser.msAuth?.logoutMSUser();
									}}
									rounded={5}
								>
									<HStack spacing="4" align="center">
										<Icon as={FaSignOutAlt} w="30px" h="30px" />
										<Text
											as="h4"
											color="gray.500"
											fontWeight="bold"
											fontSize="md"
											textTransform="uppercase"
											letterSpacing={2}
										>
											Log Out
										</Text>
									</HStack>
								</MenuItem>

								{portalUser?.user?.isPortalAdminYN === "Y" && (
									<>
										<Divider />
										<MenuItem
											color="teal.600"
											onClick={() => setImpersonateModal(true)}
											rounded={5}
											mt={2}
										>
											<HStack spacing="4" align="center">
												<Icon as={FaUserSecret} w="30px" h="30px" />
												<Text
													as="h4"
													color="gray.500"
													fontWeight="bold"
													fontSize="md"
													textTransform="uppercase"
													letterSpacing={2}
												>
													Impersonate
												</Text>
											</HStack>
										</MenuItem>
									</>
								)}
							</Stack>
						</MenuList>
					</Portal>
				</Menu>

				{sideBarButton}
			</Flex>
		</Flex>
	);
}
